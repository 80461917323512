import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { toast } from 'react-toastify';
import { registerServiceWorker } from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MixPanelUtil from './utilities/mixpanel/mixapanel';
import { datadogLogs } from '@datadog/browser-logs'


toast.configure({
  autoClose: 3000,
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
});

//datadog logs
datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: process.env.REACT_APP_DATADOG_SITE || 'datadoghq.eu', 
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service:process.env.REACT_APP_DATADOG_SERVICE || 'tconsulta-web',
  env:process.env.REACT_APP_DATADOG_ENV || 'development'
})

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
registerServiceWorker();
