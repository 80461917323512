import moment from "moment";
import { UPDATE_JOYRIDE, UPDATE_NOTIFICATIONS, CommonActionTypes, MARK_NPS_SURVEY_COMPLETED } from "../../types/commonActions"

const defaultState: {
  isUserOnboardingFirstTime: boolean;
  notifications: any[];
  isNpsSurveyCompleted: boolean;
  surveyCompletionDate: string | null;
} = {
  isUserOnboardingFirstTime: true,
  notifications: [],
  isNpsSurveyCompleted: false,
  surveyCompletionDate: null,
};

export default function (state = defaultState, action: CommonActionTypes): { isUserOnboardingFirstTime: boolean, notifications: any[], isNpsSurveyCompleted:boolean, surveyCompletionDate: null | string} {
    switch (action.type) {
        case UPDATE_JOYRIDE: return {
            ...state,
            isUserOnboardingFirstTime: !state.isUserOnboardingFirstTime,
        }

        case UPDATE_NOTIFICATIONS: return {
            ...state,
            notifications: action.payload,
        }

        case MARK_NPS_SURVEY_COMPLETED: return {
          ...state,
          isNpsSurveyCompleted: true,
          surveyCompletionDate: moment().format(),
        };

        default:
            return state
    }

}