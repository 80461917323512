import React from 'react';
import Router from './Router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/App.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import storeObj from './store/configureStore/store';

const { store, persistor } =storeObj;

function App() {
  if (process.env.REACT_APP_ENVIRONMENT === "production")
  console.log = function no_console() {};
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="main-wrapper-section">
          <Router />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
