export const UPDATE_JOYRIDE = 'UPDATE_JOYRIDE';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const MARK_NPS_SURVEY_COMPLETED = 'MARK_NPS_SURVEY_COMPLETED';

export interface updateJoyRide {
    type: typeof UPDATE_JOYRIDE;
    payload: any;
}

export interface updateNotifications {
    type: typeof UPDATE_NOTIFICATIONS;
    payload: any;
}

export interface markNpsSurveyCompleted {
    type: typeof MARK_NPS_SURVEY_COMPLETED;
    payload: any;
}

export type CommonActionTypes = updateJoyRide | updateNotifications | markNpsSurveyCompleted;