import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { getUrl } from '../utilities/helpers';
import { routeConstants } from '../constants/apiRoutes';
import { toast } from 'react-toastify';
const { LOGIN } = routeConstants;
let loginUrl = getUrl(LOGIN);

const configureAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 80000,
  });

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use(async (config: any) => {
  import('../store/configureStore/store').then(async (persistStore) => {
    const { store } = persistStore.default;
    let state = store.getState();
    if (
      state.auth &&
      state.auth.user &&
      state.auth.user.data &&
      config.url !== loginUrl
    ) {
      // console.log("token of the yser", state.auth.user.meta.token)
      (config as any).headers.common[
        'Authorization'
      ] = `Bearer ${state.auth.user.meta.token}`;
    }

    datadogLogs.logger.info(`Request to API: ${config.url}`, {
      url: config?.url,
      method: config?.method,
      requestDetails: config,
      userId:
        state && state.auth && state.auth.user && state.auth.user.data ? state.auth.user.data.id : null,
      email:
        state && state.auth && state.auth.user && state.auth.user.data 
          ? state.auth.user.data.email
          : null,
    });
  });
  // console.log('request config==>', config);

  return await config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    datadogLogs.logger.info(
      `Response received from API: ${response?.config.url}`,
      {
        url: response?.config.url,
        method: response?.config.method,
        responseDetails: response?.config,
        response: response,
        responseData: response?.data,
      },
    );
    return response;
  },
  (err) => {
    if (!err.response) {
      //internet connection issue
      toast.error('Nessuna conessione internet');
      // datadogLogs.logger.error(`No Internet connection`, {});
    } else {
      if (err.response.status === 403) {
        datadogLogs.logger.warn(`Permission denied`, {
          error: err,
          errorResponse: err.response,
        });
        toast.error('Permesso negato');
      } else if (err.response.status === 401) {
        import('../store/configureStore/store').then(async (persistStore) => {
          const { store } = persistStore.default;
          const localData = store.getState();
          if (localData?.auth?.user?.data) {
            datadogLogs.logger.warn(`Logged out`, {
              error: err,
              errorResponse: err.response,
              userId:
                localData && localData.auth && localData.auth.user && localData.auth.user.data 
                  ? localData.auth.user.data.id
                  : null,
              email:
                localData && localData.auth && localData.auth.user && localData.auth.user.data
                  ? localData.auth.user.data.email
                  : null,
            });
            toast.warn('Logout effettuato.');
            store.dispatch({
              type: 'LOGOUT_USER',
            });
          }
        });
      }

      return err.response;
    }
    datadogLogs.logger.error(`Error response from API`, {
      error: err,
      errorResponse: err.response,
    });
    return Promise.reject(err);
  },
);
