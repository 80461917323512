export const routeConstants = {
  LOGIN: '/auth/login',
  SIGNUP: '/auth/verify/:token',
  UPDATE_EMAIL: '/auth/update-email-verify/:token',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password/:token',
  RESEND_VERIFICATION_EMAIL: '/auth/resend-verification-email/:companyId',

  //COMPANY ACTIONS
  GET_COMPANIES: '/companies',
  GET_COMPANY: '/companies', //add company id when requesting
  PATCH_COMPANY: '/companies', //add company id when requesting
  DELETE_COMPANY: '/companies', //add company id when requesting
  CREATE_COMPANY: '/companies',
  IMPORT_COMPANY: '/companies/import',
  UPDATE_SUBSCRIPTION: '/companies/subscription',
  GET_LINKED_DOCTORS: '/linkedDoctors',
  LINK_DOCTORS: '/linkedDoctors',
  //TEAM MATE ACTIONS
  GET_TEAM_MATES: '/team-mates',
  CREATE_TEAM_MATE: '/team-mates/create',
  PATCH_TEAM_MATE: '/team-mates/update/:id',
  DEACTIVATE_TEAM_MATE: '/team-mates/update/:id',
  GET_COMPANIES_ANALYTICS: '/companies/data/analytics',

  //DOCTOR ACTIONS
  GET_DOCTOR_STATS: '/users', //include the url like /users/:id/work-stats
  GET_DOCTOR_STATS_PROFILE: '/users',
  GET_MY_PATIENTS: '/users/my-patients',
  GET_DOCTORS_ANALYTICS: '/users/doctor/analytics',

  //USER ACTIONS
  GET_USERS: '/users',
  GET_USER: '/users', //add user id when requesting
  PATCH_USER: '/users', //add user id when requesting
  CREATE_USER: '/users',
  CREATE_USER_CSV: '/users/import',
  DEACTIVATE_USER: '/users', //add user id when requesting
  UPDATE_PROFILE: '/users/profile',
  GET_PROFILE: 'users/profile',
  GET_USERS_ANALYTICS: '/users/data/analytics',
  GET_GENERAL_ANALYTICS: '/users/general/analytics',
  GET_USER_FAMILY_MEMBERS: '/users/family-members',
  CHANGE_EMAIL: '/users/change-email',


  //CC Actions
  GET_DOCTOR_SLOTS: '/slots/available',
  TOGGLE_CC_STATUS: '/users',
  GET_DOCTOR_BOOKED_SLOTS: '/slots/booked',


  //BLOG ACTIONS
  GET_ALL_POSTS: '/posts',

  //ROLES
  GET_ALL_ROLES: '/roles',

  //Case actions
  GET_ALL_CASES: '/cases',
  GET_MY_CASES: '/cases/my-cases',
  GET_MY_UPCOMING_APPOINTMENT: '/cases/my-next-upcoming-cases',
  ASSIGN_CC: '/cases',
  CREATE_CASE: '/cases',
  GET_CASE: '/cases',
  UPDATE_CASE: '/cases', ///append caseId to the route

  RATE_CASE: '/cases',
  RESCHEDULE_OR_CANCEL_APPOINTMENT: '/request',
  GET_OPEN_CREATE_NEW: '/cases/get-open-case',
  FETCH_COUNT: '/cases/get-open-case-count',
  START_OVER_CASE: '/cases/start-over-case',
  GET_CASES_ANALYTICS: '/cases/data/analytics',

  //Careplans actions
  CREATE_CAREPLAN: '/care-plans',
  UPDATE_CAREPLAN: '/care-plans', //append carePlan id
  DELETE_CAREPLAN: '/care-plans', //append prescription id

  //Prescriptions actions
  CREATE_PRESCRIPTION: '/prescriptions',
  UPDATE_PRESCRIPTION: '/prescriptions', //append prescription id
  DELETE_PRESCRIPTION: '/prescriptions', //append prescription id

  //Appointments
  CANCEL_APPOINTMENT: '/appointments',
  RECHEDULE_APPOINTMENT: '/appointments/:id/reschedule',
  SCHEDULE_APPOINTMENT: '/appointments',
  FIRST_APPOINTMENT: '/appointments/check-first-appointment',
  SCHEDULE_IN_PERSON_APPOINTMENT: '/appointments/inperson',
  GET_APPOINTMENTS_VIA_PAYMENT: 'appointments/appointments-via-payments',
  GET_APPOINTMENTS_VIA_CREDITS: 'appointments/appointments-via-credits',
  GET_APPOINTMENTS_VIA_FREE_CREDITS:
    'appointments/appointments-via-free-credits',

  FETCH_APPOINTMENT: '/appointments/fetch-appointment',
  GET_APPOINTMENT_FOR_SA: '/appointments/appointment-for-sa',
  GET_APPOINTMENTS_IN_LAST_WEEK: '/appointments/appointments-in-last-week',
  GET_BASKET_APPOINTMENTS: '/appointments/basket/:id',

  //Documents
  GET_MY_DOCUMENTS: '/documents/my-documents',
  DELETE_DOCUMENT: '/documents/:id',
  UPLOAD_DOCUMENT: '/documents',

  //chatbot messages
  SEND_CHATBOT_MESSAGE: '/chats/messages/bot',

  //messages
  GET_ALL_MESSAGES: '/chats/messages',
  //Chats
  GET_ALL_CHATS: '/chats/messages',
  CREATE_CHAT: '/chats/messages',
  READ_CHAT_MESSAGES: '/chats/messages/read',
  DISABLE_CHAT_MESSAGE: '/chats/messages/:id/disable',
  CREATE_IN_CHAT_APPOINMENT_MESSAGE: '/chats/messages/in-chat-appointment',
  CANCEL_IN_CHAT_APPOINMENT_MESSAGE: '/chats/messages/in-chat-appointment/cancel',
  CHAT_TYPING: '/chats/messages/typing',




  //video call
  START_VIDEO_CALL: '/video-calls',
  JOIN_VIDEO_CALL: '/video-calls/:id',
  STOP_VIDEO_CALL: '/video-calls/:id/end',
  JOIN_VIDEO_CALL_BY_URL: '/video-calls',
  CREATE_TWILIO_VIDEO_CALL: '/video-calls/twilio/:id',
  START_DAILY_VIDEO_CALL: '/video-calls/daily/call/:caseId',


  //fcm
  SUBSCRIBE_FOR_FCM: '/notifications/subscribe/fcm',
  DELETE_FCM_TOKEN: '/notifications/subscribe/fcm/:token',

  // Notifications
  GET_NOTIFICATIONS: '/notifications',
  READ_NOTIFICATION: '/notifications/:id/mark-read',
  CLEAR_ALL_NOTIFICATIONS: '/notifications',


  //pending requests
  GET_PENDING_REQUESTS: '/request/pending-requests',
  GET_SENT_REQUESTS: '/request/sent-requests',
  REJECT_REQUESTS: '/request',

  //upload and getsigned url
  POST_SIGNED_URL: '/uploads',
  INVOICE_POST_SIGNED_URL: '/uploads/manual-invoice',

  //stripe payment
  MAKE_PAYMENT: '/stripe/create-checkout-session',
  GET_PAYMENT_ID: '/stripe/checkout-session',

  //order table
  CREATE_ORDER: '/orders',
  UPDATE_ORDER: '/orders',
  GET_ORDER: '/orders',

  //payments table
  CREATE_PAYMENT: '/payments',
  UPDATE_PAYMENT: '/payments',
  GET_PAYMENT: '/payments',
  GET_PAYMENTS: '/payments',
  GET_PAYMENT_DETAILS: '/payments/:id/details',
  GET_CSID: '/payments/getCsId',

  //get slot details
  GET_SLOT_DETAILS: '/slots',
  GET_DOCTOR_AVAILABLE_DATES: '/slots/doctor',
  UPDATE_SLOT: '/slots/:id',

  //cashout
  CREATE_CASHOUT_REQUEST: '/cashout',
  GET_ACCEPTED_CASHOUTS: '/cashout/accepted-requests',
  GET_ALL_ACCEPTED_CASHOUTS: '/cashout/all-accepted-requests',
  GET_PENDING_REQUESTS_FOR_SA: 'cashout/pending/:id',
  GET_SENT_CASHOUT_REQUESTS_TO_SA: 'cashout/my-requests',
  ACKNOWLEDGE_REQUEST: '/cashout/:id',
  CANCEL_CASHOUT_REQUEST: '/cashout/cancel-cashout/:id',

  //coupons
  VALIDATE_COUPONS: '/coupons/apply',
  REMOVE_COUPON: '/orders/remove-coupon',
  GET_COUPONS: '/coupons?:query',
  DELETE_COUPON: '/coupons/:id',
  CREATE_COUPONS: '/coupons',
  UPDATE_COUPON: '/coupons/:id',
  SEARCH_COUPON: '/coupons?q=:query',

  RESET_BASKET_CREDITS: '/users/company/:id',
  // peteint dimensions test routes

  GET_DIMENSIONS: '/dimensions',
  LAST_TEST: '/assessments/last-tests',
  ASSESSMENT_TEST: '/assessments/test',
  GET_SAVED_TEST: '/assessments/save-progress',
  SAVE_PGWBI_TEST: '/assessments/save-progress',
  DELETE_PGWBI_TEST: '/assessments/delete-progress',
  DIMENSION_GRAPH: '/assessments/dimension/:id',
  PGWBI_TEST: '/assessments/pgwbi',
  PGWBI_LAST_TEST: '/assessments/pgwbi-last',
  PGWBI_REMAINING_CASES: '/assessments/pgwbi-remaining-cases',

  // peteint question

  DIMENSION_QUESTION: '/dimensions/:id/questions',
  PGWBI_QUESTIONS: '/questions/pgwbi',
  SELF_ASSESSMENT_TEST_AVAIABILITY: '/assessments/test-availability',
  GET_ALL_VIDEOS: '/videos',
  GET_ALL_WEBINARS: '/videos/webinars',
  GET_ALL_DIMENSIONS_WITH_IDS: '/dimensions/ids',
  GET_DIMENSION_WITH_ID: '/dimensions/with-id',
  PGWBI_GRAPH_DATA: '/assessments/pgwbi',
  DIMENSION_GRAPH_DATA: '/assessments/patient/monthly-score',
  DIMENSION_GRAPH_DATA_BY_COMPANY:
    '/assessments/average-score-year/company/:companyId',
  PGWBI_GRAPH_DATA_BY_COMPANY: 'assessments/average-score-year/company/:id',
  PGWBI_COMPANY_AVERAGE_SCORE: '/assessments/average-score/company/:id/',
  GET_ALL_DIMENSIONS_AVERAGE_SCORE_BY_COMPANY:
    'assessments/average-score/company/:companyId/all',
  PGWBI_LAST_COMPANY_AVERAGE_SCORE:
    '/assessments/average-score/pgwbi/company/:id/',

  CREATE_VIDEOS: '/videos',
  GET_SIGNED_URL_VIDEO: '/videos/url/signed-url',
  RECENT_USER_HISTORY: '/user-history',
  ADD_POST_USER_HISTORY: '/user-history/post',
  ADD_VIDEO_USER_HISTORY: '/user-history/video',

  GET_PDF_SIGNED_URL: '/pdfs/url/signed-url',
  CREATE_PDF: '/pdfs',
  GET_ALL_PDFS: '/pdfs',
  UPDATE_PDF: '/pdfs/:id',

  GET_QUESTIONNAIRES: '/questionnaires/user/:id',
  ADD_QUESTIONNAIRES_ANSWERS: '/answers',
  GET_QUESTIONNAIRE_DOCTORS: '/answers/doctors',

  //INVOICE:
  CREATE_MANUAL_INVOICE: '/invoice',
  UPDATE_MANUAL_INVOICE: 'invoice/edit-manual-invoice/:id',

  //Public
  GET_PUBLIC_COMPANIES: '/public/companies',
  PUBLIC_SIGNUP: '/public/auth/signup',

  //Auth
  VERIFY_GOOGLE_RECAPTCHA_TOKEN: '/auth/verify-recaptcha-token',
};
