import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { requireAuth } from './hocs/PrivateRoute';
const VideoCallNew = lazy(() => import('./containers/videoCallNew/index.js'));
const PreJoinVideoCall = lazy(
  () => import('./containers/videoCallNew/PreJoinVideoCall/index'),
);
const PreJoinVideoCallDaily = lazy(
  () => import('./containers/DailyVideoCallPreJoin/index'),
);
const TwilioVideoCall = lazy(
  () => import('./containers/twilioVideoCall/index.js'),
);
const TwilioVideoCallFunctional = lazy(
  () => import('./containers/twilioVideoCallFunctional/index'),
);

// Onboarding
// import Login from './containers/onboarding/Login';
// import ForgotPassword from './containers/onboarding/ForgotPassword';

// import ResetPassword from './containers/onboarding/ResetPassword';
// import Signup from './containers/onboarding/Signup';
// import CompleteProfile from './containers/onboarding/CompleteProfile';
// Patient Pages
// import PatientContainer from './containers/patient';

// Superadmin Pages
// import SuperadminContainer from './containers/superadmin';

// Doctor Pages
// import DoctorContainer from './containers/doctor';

// Hr Pages
// import HrContainer from './containers/hr';

// import VideoCall from './containers/videoCall/index.js';
const VideoCall = lazy(() => import('./containers/videoCall/index.js'));
// import PageNotFound from './components/PageNotFound';

const PageNotFound = lazy(() => import('./components/PageNotFound'));
const CompanySpecificPage = lazy(
  () => import('./components/CompanySpecificPage'),
);

// CareCoordinator Pages
// import CareCoordinatorContainer from './containers/careCoordinator';
// import UpdateEmail from './containers/onboarding/UpdateEmail';
// import ViewProfile from './containers/onboarding/ViewProfile';

const CareCoordinatorContainer = lazy(
  () => import('./containers/careCoordinator'),
);
const UpdateEmail = lazy(() => import('./containers/onboarding/UpdateEmail'));
const ViewProfile = lazy(() => import('./containers/onboarding/ViewProfile'));

const Login = lazy(() => import('./containers/onboarding/Login'));
const ForgotPassword = lazy(
  () => import('./containers/onboarding/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import('./containers/onboarding/ResetPassword'),
);
const Signup = lazy(() => import('./containers/onboarding/Signup'));
const CompleteProfile = lazy(
  () => import('./containers/onboarding/CompleteProfile'),
);

const PatientContainer = lazy(() => import('./containers/patient'));
const SuperadminContainer = lazy(() => import('./containers/superadmin'));
const DoctorContainer = lazy(() => import('./containers/doctor'));
const HrContainer = lazy(() => import('./containers/hr'));

const Routers: React.FC<{}> = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        {/* Onboarding Flow */}
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route exact path="/superadmin/login" component={Login} />
          <Route exact path="/doctor/login" component={Login} />
          <Route exact path="/hr/login" component={Login} />
          <Route exact path="/carecoordinator/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/signup" component={Signup} />
          <Route path="/update-email" component={UpdateEmail} />
          <Route path="/view-profile" component={ViewProfile} />
          <Route
            path="/patient/complete-profile"
            component={requireAuth(CompleteProfile)}
          />

          {/* Patient Flow */}
          <Route path="/patient" component={requireAuth(PatientContainer)} />

          {/* Superadmin Flow */}
          <Route
            path="/superadmin"
            component={requireAuth(SuperadminContainer)}
          />

          {/* Doctor Flow */}
          <Route path="/doctor" component={requireAuth(DoctorContainer)} />

          {/* Doctor Flow */}
          <Route path="/hr" component={requireAuth(HrContainer)} />

          {/* CareCoordinator Flow */}
          <Route
            path="/carecoordinator"
            component={requireAuth(CareCoordinatorContainer)}
          />
          <Route exact path="/video" component={VideoCall} />
          <Route exact path="/video/v2" component={VideoCallNew} />
          <Route exact path="/video/v2/:uuid" component={VideoCallNew} />
          <Route
            exact
            path="/video/v3/prejoin/:id"
            component={PreJoinVideoCall}
          />
          <Route
            exact
            path="/video/v4/prejoin/:id"
            component={PreJoinVideoCallDaily}
          />
          <Route exact path="/video/v3/:id" component={TwilioVideoCall} />
          {/* <Route
            exact
            path="/video/v4/"
            component={TwilioVideoCallFunctional}
          /> */}
          <Route exact path="/video/:uuid" component={VideoCall} />
          
          {/* <Route exact path="/video/v3/:id" component={TwilioVideoCall} /> */}

          {/* <Route exact path="/video2/:uuid" component={VideoCallNew} /> */}
          <Route exact path="/:companyroute" component={CompanySpecificPage} />

          <Route render={() => <PageNotFound className="outer-404-page" />} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default Routers;
